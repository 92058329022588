import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Place } from './interfaces/places.interfaces';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  constructor(private http:HttpClient) { }
  test(){

  }

  fetchPlaces(){
    // return this.http.get("assets/data/syracuse_addresses_6.json");
      return this.http.get('/alldata').pipe(
        delay(0)
      );
  }
}
