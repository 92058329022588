import { NgModule, Input } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TableComponent } from './table/table.component' 
import { RootDataPageComponent } from './root-data-page/root-data-page.component';
import { PlacesService } from './places.service';
import { PlacesResolverService } from './places-resolver.service';
import { AboutComponent } from './about/about.component';
import { TipsComponent } from './tips/tips.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';

const routes: Routes = [
  { path: 'home',      
    component: LandingPageComponent },
  { path: 'table',      
    component: TableComponent,
    resolve:{ places: PlacesResolverService }
  },
  { path: 'about',      
    component: AboutComponent,
  },
  { path: 'tips',      
    component: TipsComponent,
  },
  { path: 'terms-and-conditions',      
    component: TermsPageComponent,
  },
  { path: 'privacy-notice',      
    component: PrivacyComponent,
  },
  { path: 'contact',      
    component: ContactPageComponent,
  },
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
