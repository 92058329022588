import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'syr-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor(private http:HttpClient) { }

  ngOnInit() {
    this.http.get("assets/data/syracuse_addresses.json")
  }

}
