import { Component, OnInit } from '@angular/core';
import { PlacesService } from '../places.service';
import { Place } from '../interfaces/places.interfaces';
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'syr-root-data',
  templateUrl: './root-data-page.component.html',
  styleUrls: ['./root-data-page.component.scss']
})
export class RootDataPageComponent implements OnInit {

  constructor(private placesService:PlacesService, private http:HttpClient) { }
  places;

  ngOnInit() {
  //  this.placesService.fetchPlaces().subscribe(
  //     (data:Place[])=>{
  //       this.places = data;
  //       console.log("places", this.places)
  //     },
  //     (error) => {console.error(error)}
  //   )
  }

//   ngOnChanges(changes: SimpleChanges) {
//     // only run when property "data" changed
//     if (changes['data']) {
//         this.groupPosts = this.groupByCategory(this.data);
//     }
// }

}
