import { Injectable } from '@angular/core';
import { PlacesService } from './places.service';
import { Place } from './interfaces/places.interfaces';
import {Router,ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PlacesResolverService {

  constructor(private ps:PlacesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Object> {
    // let id = route.paramMap.get('id');

    return this.ps.fetchPlaces();
    // .subscribe(
    //   (data:Place[])=>{
    //     this.places = data;
    //     console.log("places", this.places)
    //   },
    //   (error) => {console.error(error)}
    // )
  }

}
