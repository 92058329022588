import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  MatRippleModule,
  MatDialogModule,
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSliderModule,
  MatTabsModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatMenuModule,
  MatTooltipModule,
  MatTableModule,
  MatSortModule,
  MatChipsModule
} from "@angular/material";

@NgModule({
  imports: [
    MatRippleModule,
    CommonModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSliderModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatChipsModule
  ],
  declarations: [],
  exports: [
    MatRippleModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatPaginatorModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSliderModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatChipsModule
  ]
})
export class MaterialModule {}
