import { Component, OnInit, Input, OnChanges, ViewChild, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material'; 
import { Place } from '../interfaces/places.interfaces';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'syr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, OnChanges {

  @Input('places') places;
  @ViewChild(MatSort, {static:true}) matSort: MatSort;
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;

  dataSource: MatTableDataSource<Place>;
  displayedColumns: string[] = ['name', 'street_number', 'street', 'city', 'zip_code', 'category'];
  categoryFilterValues=[];
  currentCatFilter;
  currentStreetFilter;
  currentCityFilter;
  streetFilterValues=[];
  cityFilterValues=[];
  reset:boolean=true;
  findInput;

  filterValues = {
    all:'',
    category:'',
    street:'',
    city:''
  };

  constructor(private route: ActivatedRoute,) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.filterPredicate = (data, filter: string): boolean =>{
      if (filter === 'reset') { return true } 
      let searchTerms = JSON.parse(filter);


      //default filterPredicate
      var dataStr = Object.keys(data).reduce(function (currentTerm, key) {
        return currentTerm + ((/** @type {?} */ (data)))[key] + '◬';
      }, '').toLowerCase();

      var transformedFilter = searchTerms.all.trim().toLowerCase();
      return (data.category.trim().indexOf(searchTerms.category) !== -1 || searchTerms.category == 'all')
        && (data.street.trim().indexOf(searchTerms.street) !== -1 || searchTerms.street == 'all')
        && ((data.city.trim() == searchTerms.city) || searchTerms.city == 'all' || searchTerms.city == '' )
        && dataStr.indexOf(transformedFilter) !== -1;
    };
   }

  ngOnInit() {
    this.route.data
      .subscribe(
        (data)=>{
          //sort data by name
          let sorteddata = data.places.sort(function(a, b) {
            if (!a.name){a.name = ''}
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
          this.places = sorteddata;
          this.dataSource.data = this.places;
          this.updateFilters()
          // console.log("data updated, places:", this.places)
        },
        (error) => {console.error(error)}
      )
      this.dataSource.sort = this.matSort;
      this.dataSource.paginator = this.paginator;
    
  }

  applyColumnFilter(column, colValue){
    // console.log(column, colValue)
    this.filterValues[column] = colValue;
    // console.log(this.filterValues[column])
    this.dataSource.filter = JSON.stringify(this.filterValues);
    // console.log(this.dataSource.filteredData)
    setTimeout(()=>{
    // console.log(this.dataSource.filteredData); 
    this.updateFilters(this.dataSource.filteredData)},300)
    
  }

  resetFilters(){
    this.filterValues.category='';
    this.filterValues.street='';
    this.filterValues.city='';
    this.filterValues.all='';
    this.findInput='';
    this.dataSource.filter = 'reset';
    
    setTimeout(()=>{
      this.updateFilters();
      this.currentCatFilter = 'All';
      this.currentStreetFilter = 'All';
      this.currentCityFilter = 'All';
      this.matSort.active = "name";
      this.matSort.direction = "asc";
      this.matSort.sortChange.emit();
    },100) 
  }

  ngOnChanges(changesObj: SimpleChanges){
    //replaced ngOnchanges with subscription
    // if(changesObj.places.currentValue && changesObj.places.currentValue.length > 0 &&
    //   changesObj.hasOwnProperty("places")
    // ) {
    //   this.dataSource.data = changesObj.places.currentValue;
    //   this.dataSource.sort = this.sort;
    //   this.updateFilters()
    // }  
  }

  updateFilters(data = this.places){
    // console.log(this.places.category)
    let categoryArray = data.map((place)=> place.category.trim() );

    this.categoryFilterValues = categoryArray.filter((cat, index) => categoryArray.indexOf(cat.trim()) === index ).sort();
    let streetArray = data.map((place)=> place.street );
    this.streetFilterValues = streetArray.filter((street, index) => streetArray.indexOf(street.trim()) === index ).sort();
    let cityArray = data.map((place)=> place.city );
    this.cityFilterValues = cityArray.filter((city, index) => cityArray.indexOf(city.trim()) === index ).sort();
  }
}
